import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import FeedbackForm from './components/FeedbackForm';
import FeedbackList from './components/FeedbackList';
import ThankYou from './components/ThankYou';
// import Menu from './components/Menu';

function App() {
  return (

    <Router>
            <Routes>
              <Route path="/feedback/:hotelId" element={<FeedbackForm />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/feedback-list/:hotelId" element={<FeedbackList />} />
              {/* <Route path="/menu/:hotelId" element={<Menu />} /> */}
            </Routes>
            </Router>
  );
}

export default App;
