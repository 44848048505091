import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { keyframes } from '@mui/system';

// Define animation for the logo
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

// Styled components
const ThankYouContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh', // Full viewport height
}));

const ThankYouTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const ThankYouMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(4),
}));

const Logo = styled('img')(({ theme }) => ({
  width: '120px',
  height: 'auto',
  marginBottom: theme.spacing(2),
  animation: `${bounce} 2s infinite`,
}));

const AnimatedIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: 100,
  color: theme.palette.success.main,
  marginBottom: theme.spacing(2),
}));

const ThankYou: React.FC = () => {
  return (
    <ThankYouContainer maxWidth="sm">
      <AnimatedIcon />
      <ThankYouTitle variant="h4" gutterBottom>
        Thank You for Your Feedback!
      </ThankYouTitle>
      <ThankYouMessage variant="body1" gutterBottom>
        We appreciate you taking the time to provide us with your valuable feedback. 
        Your input helps us improve and provide a better experience for all our guests.
      </ThankYouMessage>
    </ThankYouContainer>
  );
};

export default ThankYou;
