import React, { useEffect, useState } from 'react';
import { 
  Container, Typography, Paper, Box, Divider, Grid, Chip, Avatar 
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { useParams } from 'react-router-dom'; // Import useParams
import { getAllFeedbacks } from '../services/FeedbackService';
import StarIcon from '@mui/icons-material/Star';
import FaceIcon from '@mui/icons-material/Face';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import MoodIcon from '@mui/icons-material/Mood';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TimerIcon from '@mui/icons-material/Timer';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface Feedback {
  id: string;
  mobile: string;
  serviceRating?: number | null;
  foodQualityRating?: number | null;
  cleanlinessRating?: number | null;
  ambienceRating?: number | null;
  valueForMoneyRating?: number | null;
  staffFriendliness?: number | null;
  orderAccuracy?: number | null;
  waitTime?: number | null;
  additionalComment?: string;
  visitFrequency?: string;
}

const FeedbackList: React.FC = () => {
  const { hotelId } = useParams<{ hotelId: string }>(); // Extract hotelId from URL
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      if (!hotelId) {
        console.error('Hotel ID is missing');
        return;
      }

      try {
        const data = await getAllFeedbacks(hotelId);
        if (Array.isArray(data)) {
          setFeedbacks(data);
        } else {
          console.error('Unexpected response format:', data);
        }
      } catch (error) {
        console.error('Error fetching feedbacks', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, [hotelId]);

  const calculateOverallRating = (feedback: Feedback): number => {
    const ratings = [
      feedback.serviceRating,
      feedback.foodQualityRating,
      feedback.cleanlinessRating,
      feedback.ambienceRating,
      feedback.valueForMoneyRating,
      feedback.staffFriendliness,
      feedback.orderAccuracy,
      feedback.waitTime,
    ].filter((rating): rating is number => rating !== null && rating !== undefined);

    const total = ratings.reduce((acc, rating) => acc + rating, 0);
    return ratings.length > 0 ? total / ratings.length : 0;
  };

  const overallAverageRating = (): number => {
    const allRatings = feedbacks.flatMap(feedback => [
      feedback.serviceRating,
      feedback.foodQualityRating,
      feedback.cleanlinessRating,
      feedback.ambienceRating,
      feedback.valueForMoneyRating,
      feedback.staffFriendliness,
      feedback.orderAccuracy,
      feedback.waitTime,
    ].filter((rating): rating is number => rating !== null && rating !== undefined));

    const total = allRatings.reduce((acc, rating) => acc + rating, 0);
    return allRatings.length > 0 ? total / allRatings.length : 0;
  };

  if (loading) {
    return <Typography variant="h6" align="center">Loading...</Typography>;
  }

  if (feedbacks.length === 0) {
    return <Typography variant="h6" align="center">No feedbacks available</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Box mt={5}>
        <Typography variant="h4" gutterBottom align="center">
          Feedback List
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* Summary Section */}
        <Paper elevation={4} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom align="center">
            Overall Feedback Summary
          </Typography>
          <Typography variant="h6" align="center">
            Average Rating: 
            <Rating value={overallAverageRating()} readOnly precision={0.5} />
          </Typography>
        </Paper>

        <Grid container spacing={3}>
          {feedbacks.map((feedback) => (
            <Grid item xs={12} sm={6} md={4} key={feedback.id}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: '8px' }}>
                <Box display="flex" alignItems="center" mb={1}>
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>{feedback.mobile[0]}</Avatar>
                  <Typography variant="h6">Mobile: {feedback.mobile}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />

                <Box mb={2}>
                  <Typography variant="body1">Overall Rating:</Typography>
                  <Rating value={calculateOverallRating(feedback)} readOnly precision={0.5} icon={<StarIcon />} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <FaceIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Service Quality:</Typography>
                  <Rating value={feedback.serviceRating || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <RestaurantIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Food Quality:</Typography>
                  <Rating value={feedback.foodQualityRating || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <CleanHandsIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Cleanliness:</Typography>
                  <Rating value={feedback.cleanlinessRating || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <MoodIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Ambience:</Typography>
                  <Rating value={feedback.ambienceRating || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <MonetizationOnIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Value for Money:</Typography>
                  <Rating value={feedback.valueForMoneyRating || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <ThumbUpIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Staff Friendliness:</Typography>
                  <Rating value={feedback.staffFriendliness || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <CheckCircleIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Order Accuracy:</Typography>
                  <Rating value={feedback.orderAccuracy || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                  <TimerIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">Wait Time:</Typography>
                  <Rating value={feedback.waitTime || 0} readOnly precision={0.5} />
                </Box>

                <Box mb={2}>
                  <Typography variant="body1">Additional Comments:</Typography>
                  <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                    {feedback.additionalComment || 'No comments'}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body1">Visit Frequency:</Typography>
                  <Chip 
                    label={feedback.visitFrequency || 'Not specified'} 
                    color="primary" 
                    variant="outlined" 
                    sx={{ mt: 1 }} 
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default FeedbackList;
