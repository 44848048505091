import api from './api';


export const submitFeedback = async (feedback: any) => {
  try {
    const response = await api.post('/restaurantFeedback', feedback);
    return response.data;
  } catch (error) {
    console.error('Error submitting feedback', error);
    throw error;
  }
};

export const getAllFeedbacks = async (hotelId: string) => {
  try {
    const response = await api.get('/restaurantFeedback', {
      params: { hotelId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching feedbacks', error);
    throw error;
  }
};
