import React, { useState } from 'react';
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Divider,
  Grid,
  Paper,
  Rating,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { submitFeedback } from '../services/FeedbackService';
import { useNavigate, useParams } from 'react-router-dom';

const HOTEL_NAME_EN = 'Hotel Dev Krishna Inn';
const HOTEL_NAME_HI = 'होटल देव कृष्णा इन';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.warning.main,
  },
  '& .MuiRating-iconEmpty': {
    color: theme.palette.action.disabled,
  },
}));

const FeedbackForm = () => {
  const { hotelId } = useParams<{ hotelId: string }>(); // Get hotelId from URL params
  const navigate = useNavigate();

  const [mobile, setMobile] = useState('');
  const [serviceRating, setServiceRating] = useState<number | null>(null);
  const [foodQualityRating, setFoodQualityRating] = useState<number | null>(null);
  const [cleanlinessRating, setCleanlinessRating] = useState<number | null>(null);
  const [ambienceRating, setAmbienceRating] = useState<number | null>(null);
  const [valueForMoneyRating, setValueForMoneyRating] = useState<number | null>(null);
  const [staffFriendliness, setStaffFriendliness] = useState<number | null>(null);
  const [orderAccuracy, setOrderAccuracy] = useState<number | null>(null);
  const [waitTime, setWaitTime] = useState<number | null>(null);
  const [additionalComment, setAdditionalComment] = useState('');
  const [visitFrequency, setVisitFrequency] = useState('');
  const [language, setLanguage] = useState('en');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string) => {
    setLanguage(newLanguage);
  };

  const handleSubmit = async () => {
    try {
      await submitFeedback({
        hotelId, // Include hotelId in feedback submission
        mobile,
        serviceRating,
        foodQualityRating,
        cleanlinessRating,
        ambienceRating,
        valueForMoneyRating,
        staffFriendliness,
        orderAccuracy,
        waitTime,
        additionalComment,
        visitFrequency,
      });
      setSuccess(true);
      setMobile('');
      setServiceRating(null);
      setFoodQualityRating(null);
      setCleanlinessRating(null);
      setAmbienceRating(null);
      setValueForMoneyRating(null);
      setStaffFriendliness(null);
      setOrderAccuracy(null);
      setWaitTime(null);
      setAdditionalComment('');
      setVisitFrequency('');
      navigate('/thank-you');
    } catch (err) {
      setError('Failed to submit feedback');
    }
  };

  const hotelName = language === 'en' ? HOTEL_NAME_EN : HOTEL_NAME_HI;
  const feedbackTitle = language === 'en' ? 'We Value Your Feedback!' : 'हम आपकी राय की कद्र करते हैं!';
  const successMessage = language === 'en' ? 'Feedback submitted successfully!' : 'फीडबैक सफलतापूर्वक सबमिट हो गया!';
  const errorMessage = language === 'en' ? 'Failed to submit feedback' : 'फीडबैक सबमिट करने में विफल';

  return (
    <Container maxWidth="sm">
      {hotelId ? ( // Only show form if hotelId is present
        <Box mt={5}>
          <Paper elevation={4} sx={{ p: 4, borderRadius: '12px', backgroundColor: '#fdfdfd' }}>
            <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              {hotelName}
            </Typography>
            <Typography variant="h5" align="center" gutterBottom sx={{ mb: 3, color: '#424242' }}>
              {feedbackTitle}
            </Typography>
            {success && <Typography color="success.main" align="center">{successMessage}</Typography>}
            {error && <Typography color="error.main" align="center">{errorMessage}</Typography>}
            <Divider sx={{ my: 3 }} />

            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <ToggleButtonGroup
                value={language}
                exclusive
                onChange={handleLanguageChange}
                aria-label="language"
                size="small"
                sx={{ mb: 3 }}
              >
                <ToggleButton value="en" aria-label="English">
                  English
                </ToggleButton>
                <ToggleButton value="hi" aria-label="Hindi">
                  हिंदी
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label={language === 'en' ? 'Mobile Number' : 'मोबाइल नंबर'}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  type="tel"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {language === 'en' ? 'How would you rate the following?' : 'आप निम्नलिखित को कैसे रेट करेंगे?'}
                </Typography>
              </Grid>
              {[
                { labelEn: 'Service Quality', labelHi: 'सेवा गुणवत्ता', value: serviceRating, setValue: setServiceRating },
                { labelEn: 'Food Quality', labelHi: 'खाद्य गुणवत्ता', value: foodQualityRating, setValue: setFoodQualityRating },
                { labelEn: 'Cleanliness', labelHi: 'स्वच्छता', value: cleanlinessRating, setValue: setCleanlinessRating },
                { labelEn: 'Ambience', labelHi: 'आभा', value: ambienceRating, setValue: setAmbienceRating },
                { labelEn: 'Value for Money', labelHi: 'पैसे की वैल्यू', value: valueForMoneyRating, setValue: setValueForMoneyRating },
                { labelEn: 'Staff Friendliness', labelHi: 'स्टाफ की मित्रता', value: staffFriendliness, setValue: setStaffFriendliness },
                { labelEn: 'Order Accuracy', labelHi: 'आदेश सटीकता', value: orderAccuracy, setValue: setOrderAccuracy },
                { labelEn: 'Wait Time', labelHi: 'प्रतीक्षा समय', value: waitTime, setValue: setWaitTime },
              ].map(({ labelEn, labelHi, value, setValue }, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Typography variant="body1">{language === 'en' ? labelEn : labelHi}</Typography>
                  <StyledRating
                    value={value}
                    onChange={(event, newValue) => setValue(newValue)}
                    precision={0.5}
                    size="large"
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  label={language === 'en' ? 'Additional Comments' : 'अधिक टिप्पणियाँ'}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={additionalComment}
                  onChange={(e) => setAdditionalComment(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel>{language === 'en' ? 'How often do you visit us?' : 'आप कितनी बार हमारे पास आते हैं?'}</InputLabel>
                  <Select
                    value={visitFrequency}
                    onChange={(e) => setVisitFrequency(e.target.value)}
                    label={language === 'en' ? 'How often do you visit us?' : 'आप कितनी बार हमारे पास आते हैं?'}
                  >
                    <MenuItem value="First time">{language === 'en' ? 'First time' : 'पहली बार'}</MenuItem>
                    <MenuItem value="Occasionally">{language === 'en' ? 'Occasionally' : 'कभी-कभी'}</MenuItem>
                    <MenuItem value="Regularly">{language === 'en' ? 'Regularly' : 'नियमित रूप से'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Button variant="contained" color="primary" onClick={handleSubmit} size="large">
                {language === 'en' ? 'Submit Feedback' : 'फीडबैक सबमिट करें'}
              </Button>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Typography variant="h6" align="center" color="error" mt={5}>
          {language === 'en' ? 'Invalid hotel ID!' : 'अमान्य होटल आईडी!'}
        </Typography>
      )}
    </Container>
  );
};

export default FeedbackForm;
